<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">监考中心</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="考试名称" class="searchboxItem ci-full">
              <span class="itemLabel">考试名称:</span>
              <el-input v-model="pageDatas.examinationName" type="text" size="small" placeholder="请输入考试名称" clearable />
            </div>
            <div title="职业名称" class="searchboxItem ci-full">
              <span class="itemLabel">职业名称:</span>
              <el-select v-model="pageDatas.occupationId" filterable remote clearable size="small"
                :remote-method="getOccupation" placeholder="请至少输入两个字搜索">
                <el-option v-for="item in occupation" :key="item.occupationId" :label="item.occupationName"
                  :value="item.occupationId">
                </el-option>
              </el-select>
            </div>
            <div title="考试科目" class="searchboxItem ci-full">
              <span class="itemLabel">考试科目:</span>
              <el-select v-model="pageDatas.subjectCode" filterable clearable size="small" placeholder="请选择考试科目">
                <el-option v-for="item in subjectCodeList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
              <el-table-column label="考试名称" align="left" show-overflow-tooltip prop="examinationName" minWidth="150" />
              <el-table-column label="职业名称" align="left" show-overflow-tooltip prop="occupationName" minWidth="150" />
              <el-table-column label="等级" align="left" show-overflow-tooltip prop="levelCode" minWidth="100">
                <template slot-scope="scope">
                  {{ $setDictionary("ET_TRAIN_LEVEL", scope.row.levelCode) }}
                </template>
              </el-table-column>
              <el-table-column label="考试科目" align="left" show-overflow-tooltip prop="subjectCode" minWidth="100">
                <template slot-scope="scope">
                  {{ $setDictionary("EA_EXAM_SUBJECT_CODE", scope.row.subjectCode) }}
                </template>
              </el-table-column>
              <el-table-column label="考试时间" align="left" show-overflow-tooltip min-width="300px">
                <template slot-scope="scope">
                  {{ scope.row.startTime | moment }} 至 {{ scope.row.endTime | moment }}
                </template>
              </el-table-column>
              <el-table-column label="考试人数" align="left" show-overflow-tooltip prop="examUserNum" minWidth="100">
                <template slot-scope="scope">
                  {{ scope.row.examUserNum ? scope.row.examUserNum : 0 }}
                </template>
              </el-table-column>
              <el-table-column label="考试状态" align="left" show-overflow-tooltip prop="examState" minWidth="100">
                <template slot-scope="scope">
                  <span v-if="scope.row.examState == '10'">未开始</span>
                  <span v-else-if="scope.row.examState == '20'">进行中</span>
                  <span v-else-if="scope.row.examState == '30'">已结束</span>
                  <span v-else>--</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="80px" fixed="right">
                <template slot-scope="scope">
                  <el-button type="text" style="padding: 0px 5px" size="mini" @click="enterInvigilation(scope.row)">监考</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "examination_onlineTest_aroundCenterList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      pageDatas: {
        examinationName: "", // 考试名称
        occupationId:"", // 职业名称
        subjectCode: "", // 考试类型
      },
      // 考试类型 - 下拉数据
      subjectCodeList: [],
      // 职业名称 - 下拉数据
      occupation: [],
    };
  },
  created() {
    this.dataDictionary();
  },
  watch: {},
  computed: {},
  mounted() {},
    methods: {
    // 获取 - 职业名称
    getOccupation(occupationName) {
      if (occupationName.trim().length >= 2) {
        this.$post("/biz/examination_affairs/activity/selectCtOccupationList", { occupationName }, 3000, true, 6)
          .then((ret) => {
            this.occupation = ret.data
          })
          .catch((err) => {
            return;
          });
      } else {
        this.occupation = [];
      }
    },
    // 获取 - 数据字典
    dataDictionary() {
      // 考试类型
      const subjectCodeList = this.$setDictionary(
        "EA_EXAM_SUBJECT_CODE",
        "list"
      );
      const list1 = [];
      for (const key in subjectCodeList) {
        list1.push({
          value: key,
          label: subjectCodeList[key],
        });
      }
      this.subjectCodeList = list1;
    },
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.pageDatas.examinationName) {
        params.examinationName = this.pageDatas.examinationName;
      }
      if (this.pageDatas.occupationId) {
        params.occupationId = this.pageDatas.occupationId;
      }
      if (this.pageDatas.subjectCode) {
        params.subjectCode = this.pageDatas.subjectCode;
      }
      this.doFetch({
        url: "/biz/examination/invigilation/pageList",
        params,
        pageNum,
      }, true, 6);
      setTimeout(()=>{
        console.log(this.tableData)
      },1000)
    },
    // 操作 - 进入监考
    enterInvigilation(row) {
      if(row.examState != '30'){
        this.$router.push({
          path: "/web/examination/onlineTest/aroundCenterInfo",
          query: {
            examinationId: row?.examinationId ?? '',
          }
        });
      } else {
        this.$message({
          message: '考试已结束',
          type: 'error'
        });
      }
    },
  },
};
</script>
<style lang="less" scoped></style>
  